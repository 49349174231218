import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerMui({
  label,
  onChange,
  value,
  ...rest
}: {
  label?: string;
  onChange: (date?: any) => void;
  value: string | null | object;
  InputProps?: object;
  sx?: object;
  maxDate?: any;
  minDate?: any;
  views?: any;
}) {
  return (
    <DatePicker
      label={label}
      onChange={(e: any) => onChange(e)}
      value={value}
      format="DD-MM-YYYY"
      {...rest}
    />
  );
}
