import lodash from 'lodash';
export const initialState = {
  vender_name: '',
  vender_code: '',
  // all address detail
  address: '',
  state: '',
  district: '',
  pincode: '',
  // end of all address detail
  // start of md details
  contact_person: '', // md name
  contact_no: null, // md
  fax: '', // md
  email_id: '',
  commodity: '', // md materials
  // end of md details
  remarks: '',
  // other contact details
  quality_head_name: '',
  quality_head_telephone: '',
  quality_head_email: '',
  supply_chain_head_name: '',
  supply_chain_head_telephone: '',
  supply_chain_head_email: '',
  plant_head_name: '',
  plant_head_telephone: '',
  plant_head_email: '',
  // certificate
  iso_9001_attach_copy: '', // when doc is uploaded yes/no
  // legal information
  factory_reg_no: '',
  gst_reg_no: '',
  // details of machinery
  machine_details: Array.from({ length: 5 }, (_) => ({
    ...lodash.cloneDeep({
      desc: '',
      qty: null,
      make: '',
      date_of_mfg: null,
    }),
  })),
  file2:[]
};
