import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TFoot from './TFoot';

interface IProps {
  moduleData: any;
  // machineData: any;
  // customerData: any;
  isView: boolean;
  handelDeleteRows: any;
  calculateCumulative: any;
  setModuleData: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  // machineData,
  // customerData,
  calculateCumulative,
  setModuleData,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            // machineData,
            // customerData,
            calculateCumulative,
            setModuleData,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
