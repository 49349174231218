import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.specs.map((spec: any, index: number) => (
        <tr key={spec._id}>
          <td>{index + 1}</td>
          <td style={{ width: '200px', minWidth: '200px' }}>
            {spec.parameter}
          </td>
          <td style={{ width: '200px', minWidth: '200px' }}>
            {spec.specification}
          </td>
          <td style={{ width: '200px', minWidth: '200px' }}>{spec.methods}</td>
          {Array.from({ length: 10 }, (_, obsIndex) => (
            <td key={obsIndex + 'obs'} style={{ textTransform: 'uppercase' }}>
              <ObservationInput
                {...{ spec, setRender, isView, obsIndex }}
                obsNumber={0}
                checkType={spec.appearance}
              />
            </td>
            // <td style={{ width: '150px', minWidth: '150px' }}>
            //   <TableInput
            //     isView={isView}
            //     name="obs"
            //     type="text"
            //     value={spec.check_0_values[obsIndex]}
            //     onChange={(e) => {
            //       spec.check_0_values[obsIndex] = e.target.value;
            //       setRender((prev) => !prev);
            //     }}
            //   />
            // </td>
          ))}
          <td style={{ textTransform: 'uppercase' }}>
            {spec.appearance === 'check3' ? (
              <CustomSelect
                name="dropdown"
                value={spec.status || ''}
                onChange={(e) => {
                  spec.status = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  minWidth: '90px',
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NG</MenuItem>
              </CustomSelect>
            ) : (
              spec.status
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              name="verified_by"
              type="text"
              style={{
                minWidth: '100px',
              }}
              value={spec?.verified_by}
              onChange={(e) => {
                spec.verified_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ width: '150px', minWidth: '150px' }}>
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={spec?.remarks}
              onChange={(e) => {
                spec.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
