import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
        values={['FRM-MNT-A-011', '00', '01-04-2018']}
        heading="Machine Maintenance History"
      />
      <tr>
        <th colSpan={4}>Machine Details </th>
        <th
          colSpan={2}
          rowSpan={4}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          Machine Maintenance Record
        </th>
        <th colSpan={2} align="left">
          Prev. Maint. Frequency
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="prev_maintenance_freq"
            type="text"
            value={moduleData.prev_maintenance_freq}
            onChange={(e) => {
              moduleData.prev_maintenance_freq = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={2} align="left">
          Month
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine Name
        </th>
        <th colSpan={2} align="left">
          {moduleData.machine_name}
        </th>
        <th colSpan={2} align="left">
          Machine Maker
        </th>
        <th colSpan={2} align="left">
          {moduleData?.make}
          {/* <TableInput
            name="machine_maker"
            type="text"
            value={moduleData.machine_maker}
            onChange={(e) => {
              moduleData.machine_maker = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          /> */}
        </th>
        <th colSpan={2} rowSpan={3}>
          <TableInput
            name="month"
            type="text"
            value={moduleData.month}
            onChange={(e) => {
              moduleData.month = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine Code
        </th>
        <th colSpan={2} align="left">
          {moduleData.machine_no}
        </th>
        <th colSpan={2} align="left">
          Location
        </th>
        <th colSpan={2} align="left">
          {moduleData?.department_id?.department_name}
          {/* <TableInput
            name="area"
            type="text"
            value={moduleData.area}
            onChange={(e) => {
              moduleData.area = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          /> */}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Capacity
        </th>
        <th colSpan={2} align="left">
          {moduleData.capacity}
        </th>
        <th colSpan={2} align="left">
          Mfg. Date
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.mfg_date)}
        </th>
      </tr>
      <tr>
        <th
          colSpan={12}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
        >
          Maintenance Details
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'Date',
          'Time',
          'Maint.',
          'Reason for Maint.',
          'Action Taken',
          'Rectification',
          'Idle Hrs./ Days',
          'Sign.',
          'Remarks',
        ].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 2 || index === 5 || index === 8 ? 2 : 1}
            rowSpan={index === 2 || index === 5 ? 1 : 2}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['B/D', 'Prev.', 'Date', 'Time'].map((head, index) => (
          <th key={head + index} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
