import React, { useState } from 'react';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import { FlexBox } from '../../../../../components/common';
import { IModuleData, IProcess, ISpec } from '../../../helpers/Interface';
import AddSpecRow from './AddSpecRow';
import ModalCustom from '../../../../../components/common/Modal';
import SpecsInputs from './SpecsInputs';
import {
  toSuperScript,
  toSuperScriptMod,
} from '../../../../../components/common/SuperScript';
import { spec as IntialSpec } from '../../../helpers/initialState';
import useConfirm from '../../../../../components/common/useConfirm';
interface IProps {
  moduleData: IModuleData;
  setRender?: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  setExpandState: React.Dispatch<React.SetStateAction<any>>;
  removeProcess: (index: number) => void;
  process: IProcess;
  index: number;
  handleChangeProcess: (e: any, index: any) => any;
  expandState: any;
}

const Process = ({
  moduleData,
  setModuleData,
  removeProcess,
  process,
  index,
  handleChangeProcess,
  setExpandState,
  expandState,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [DialogDeleteSpec, confirmDeleteSpec] = useConfirm(
    'DELETE SPECS',
    'Are you sure you want to delete this specs?',
  );
  const [specModalData, setSpecModalData] = useState<ISpec>({
    ...IntialSpec,
    s_no: moduleData.processes[index].specs.length + 1,
  });

  const setSpecsAndModal = (spec: any) => {
    setSpecModalData(spec);
    setOpenModal(true);
  };

  const sortProcess = () => {
    moduleData.processes[index].specs.sort(
      (a: ISpec, b: ISpec) => a.s_no - b.s_no,
    );
    setRender((prev) => !prev);
  };

  const handleSnoAndSorting = (e: any, specs: any) => {
    specs[e.target.name] = e.target.value;
    sortProcess();
  };

  function handleAppearance(e: any, specs: ISpec) {
    specs.appearance = e.target.name;
    setRender((prev) => !prev);
  }

  const handleSpec = (e: any, specs: ISpec) => {
    let value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    specs[e.target.name] = value;

    if (e.target.name === 'control_product') {
      let convertedValue = toSuperScriptMod(value);
      specs[e.target.name] = convertedValue;
      setRender((prev) => !prev);
      return;
    }

    specs[e.target.name] = value;
    setRender((prev) => !prev);
  };

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    moduleData.processes[index].specs.forEach((specs, index) => {
      specs[e.target.name] = value;
    });
    setRender((prev) => !prev);
  };

  return (
    <>
      <div
        style={{
          padding: '0rem',
          border: '1px solid #e9ecef',
          width: '99%',
          margin: '1rem auto 1rem auto',
          background: '#fafafa',
        }}
      >
        <AddSpecRow
          {...{
            moduleData,
            setModuleData,
            removeProcess,
            process,
            index,
            handleChangeProcess,
            setExpandState,
            expandState,
            setRender,
          }}
        />
        {expandState[index] && (
          <Grid
            container
            xs={12}
            gap={2}
            sx={{
              borderTop: '1px solid #e9ecef',
              borderLeft: '1px solid #e9ecef',
              borderRight: '1px solid #e9ecef',
              // borderBottom: '1px solid #e9ecef',
              marginTop: '5px',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <Grid item xs={2} sx={{ background: '#fafafa' }}>
              <InputMuiCustom
                type="text"
                name="process_no"
                value={process?.process_no || ''}
                placeholder="Operation Number"
                onChange={(e) => handleChangeProcess(e, index)}
                sx={{ backgroundColor: '#fafafa' }}
              />
            </Grid>
            <Grid item xs={2}>
              <InputMuiCustom
                type="text"
                name="process_name"
                value={process?.process_name || ''}
                placeholder="Process Description"
                onChange={(e) => handleChangeProcess(e, index)}
              />
            </Grid>
          </Grid>
        )}
        {expandState[index] &&
          moduleData.processes[index]?.specs?.length > 0 && (
            <>
              <div
                style={{
                  marginBottom: '0.3rem',
                  marginTop: '0.6rem',
                  padding: '0rem',
                  fontSize: '1.6rem',
                }}
              >
                <Grid
                  container
                  xs={12}
                  gap={0.9}
                  alignItems={'center'}
                  sx={{
                    borderTop: '1px solid #e9ecef',
                    borderLeft: '1px solid #e9ecef',
                    borderRight: '1px solid #e9ecef',
                    borderBottom: '1px solid #e9ecef',
                    marginTop: '5px',
                    alignItems: 'center',
                    marginBottom: '5px',
                    height: '50px',
                  }}
                >
                  <Grid item xs={1}></Grid>
                  <Grid item xs={2.9}></Grid>
                  <Grid item xs={1} sx={{ fontWeight: 500 }}>
                    Add All to ⟾
                  </Grid>
                  <Grid item xs={1}>
                    <div>
                      <input
                        type="checkbox"
                        name="spec_to_pir"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        Line Inspection
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <div>
                      <input
                        type="checkbox"
                        name="spec_to_pdir"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        Final Inspection
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={0.9}>
                    <div>
                      <input
                        type="checkbox"
                        name="spec_to_setup"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        FPA / LPA
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    {/* <div>
                      <input
                        type="checkbox"
                        name="spec_to_Incoming_Report"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        Material Inspection
                      </span>
                    </div> */}
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        {expandState[index] &&
          process?.specs &&
          process.specs.map((spec, specsIndex) => (
            <div
              key={specsIndex}
              style={{
                marginBottom: '0.3rem',
                marginTop: '0.6rem',
                padding: '0rem',
              }}
            >
              <Grid
                container
                xs={12}
                sx={{
                  background: '#fafafa',
                  alignItems: 'center',
                  fontSize: '1.6rem',
                }}
              >
                <Grid item xs={1}>
                  <InputMuiCustom
                    type="number"
                    name="s_no"
                    placeholder="S No"
                    onChange={(e) => handleSnoAndSorting(e, spec)}
                    value={spec.s_no}
                    // style={{ width: '80px' }}
                  />
                </Grid>
                <Grid item xs={0.8}></Grid>
                <Grid item xs={2.2}>
                  {(spec.char_product || '') +
                    (spec.char_product ? '/' : '') +
                    (spec.char_process || '')}
                </Grid>
                <Grid item xs={1.1} sx={{ fontWeight: 500 }}>
                  Add to ⟾
                </Grid>
                <Grid item xs={1}>
                  <div>
                    <input
                      type="checkbox"
                      name="spec_to_pir"
                      onChange={(e) => handleSpec(e, spec)}
                      checked={spec.spec_to_pir}
                    />
                    Line Inspection
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div>
                    <input
                      type="checkbox"
                      name="spec_to_pdir"
                      onChange={(e) => handleSpec(e, spec)}
                      checked={spec.spec_to_pdir}
                    />
                    Final Inspection{' '}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div>
                    <input
                      type="checkbox"
                      name="spec_to_setup"
                      onChange={(e) => handleSpec(e, spec)}
                      checked={spec.spec_to_setup}
                    />
                    FPA / LPA
                  </div>
                </Grid>
                <Grid item xs={1.9}>
                  {/* <div>
                    <input
                      type="checkbox"
                      name="spec_to_Incoming_Report"
                      onChange={(e) => handleSpec(e, spec)}
                      checked={spec.spec_to_Incoming_Report}
                    />
                    Material Inspection
                  </div> */}
                </Grid>
                <Grid item xs={2}>
                  <FlexBox justify="end">
                    <FlexBox
                      style={{
                        color: '#fafafa',
                        backgroundColor: '#161A1D',
                        height: '45px',
                        width: '130px',
                        minWidth: '130px',
                        gap: '0.5',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSpecsAndModal(spec);
                      }}
                    >
                      <EditIcon
                        style={{
                          margin: '0 0.4rem 0 0.6rem ',
                          fontSize: '1.6rem',
                        }}
                      />
                      <span>Edit Specs</span>
                    </FlexBox>
                    <FlexBox
                      style={{
                        height: '45px',
                        width: '45px',
                        minWidth: '45px',
                        color: '#f1f3f5',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#D70012',
                      }}
                      onClick={async () => {
                        const isDelete = await confirmDeleteSpec();
                        if (isDelete) {
                          process?.specs && process.specs.splice(specsIndex, 1);
                          setRender((prev: any) => !prev);
                        }
                      }}
                    >
                      <DeleteIcon style={{ color: '#f8f9fa' }} />
                    </FlexBox>
                  </FlexBox>
                </Grid>
              </Grid>
            </div>
          ))}
      </div>
      <DialogDeleteSpec isSubmitContent={false} />
      <ModalCustom
        title={'CONTROL PLAN SPECS'}
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      >
        <SpecsInputs
          {...{
            specModalData,
            openModal,
            setOpenModal,
            handleSpec,
            handleAppearance,
          }}
        />
      </ModalCustom>
    </>
  );
};

export default Process;
