import { AiOutlineCloudUpload } from 'react-icons/ai';
const tiles = [
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=masterProcedureClause18&heading=MASTER PROCEDURE',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'MASTER',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/clauseUploadList?documentType=noiseLevelInShopFloorClause18&heading=NOISE LEVEL IN SHOP FLOOR',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'NOISE LEVEL IN',
    span: 'SHOP FLOOR',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/clauseUploadList?documentType=layoutAndEscapeClause18&heading=LAYOUT AND ESCAPE ROUTES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'LAYOUT AND ESCAPE',
    span: 'ROUTES',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/clauseUploadList?documentType=availabilityOfSafetyStandardsClause18&heading=AVAILABILITY OF SAFETY STANDARDS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'AVAILABILITY OF SAFETY',
    span: 'STANDARDS',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/clauseUploadList?documentType=awarnessOfSafetyOperatorsClause18&heading=AWARENESS OF SAFETY TO OPERATORS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'AWARENESS OF SAFETY',
    span: 'TO OPERATORS',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/clauseUploadList?documentType=availabilityOfPpesClause18&heading=AVAILABILITY OF PPES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'AVAILABILITY OF',
    span: 'PPES',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/clauseUploadList?documentType=doorSensorsOnMachinesClause18&heading=DOOR SENSORS ON MACHINES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'DOOR SENSORS',
    span: 'ON MACHINES',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/clauseUploadList?documentType=doubleHandOperationClause18&heading=DOUBLE HAND OPERATION',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'DOUBLE HAND',
    span: 'OPERATION',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/clauseUploadList?documentType=usageOfPpesByOperatorsClause18&heading=USAGE OF PPES BY OPERATORS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'USAGE OF PPES',
    span: 'OPERATORS',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/clauseUploadList?documentType=accidentReportingMechanismClause18&heading=ACCIDENT REPORTING MECHANISM',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'ACCIDENT REPORTING',
    span: 'MECHANISM',
    isIcon: true,
  },
  {
    sNo: 11,
    link: '/common/clauseUploadList?documentType=countermeasuresAgainstAccidentsClause18&heading=COUNTERMEASURES AGAINST ACCIDENTS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'COUNTERMEASURES AGAINST',
    span: 'ACCIDENTS',
    isIcon: true,
  },
  {
    sNo: 12,
    link: '/common/clauseUploadList?documentType=periodicVerificationOfCounterMeasuresClause18&heading=PERIODIC VERIFICATION OF COUNTERMEASURES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'PERIODIC VERIFICATION OF',
    span: 'COUNTERMEASURES',
    isIcon: true,
  },
  {
    sNo: 13,
    link: '/common/clauseUploadList?documentType=firSafetyAuditClause18&heading=FIRE SAFETY AUDIT REPORT',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'FIRE SAFETY',
    span: 'AUDIT REPORT',
    isIcon: true,
  },
];

export default tiles;
