import mushinlabFullLogo from '../../assets/images/ARC Logo.png';
import mushinlabHalfLogo from '../../assets/images/WHITE.png';
import logo from '../../assets/images/DP Mushin Labs.png';

export const proxy_img: string = 'https://proxy.aqua.mushinlabs.com/proxy/';

interface ImagesType {
    mushinlabFullLogo: string;
    mushinlabHalfLogo: string;
    companyImg: string;
    moduleCompanyImgHeader: string;
    companyLogo: string;
}

export const images: ImagesType = {
    mushinlabFullLogo: mushinlabFullLogo,
    mushinlabHalfLogo: mushinlabHalfLogo,
    companyImg: mushinlabHalfLogo,
    moduleCompanyImgHeader: mushinlabHalfLogo,
    companyLogo: logo,
};

export const textInputs: {
    companyName: string;
} = {
    companyName: 'MUSHIN INNOVATION LABS',
};
