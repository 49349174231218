import { SetStateAction, useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import OperationSelect from '../OperationSelect';
import Operator from './Operator';

interface Iporps {
  index: number;
  autoCompleteMachineValue: any;
  isView: any;
  process: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  machineData: any;
}
const TBodyRow = ({
  index,
  autoCompleteMachineValue,
  isView,
  process,
  setIsOpenPopup,
  setPartIdx,
  machineData,
}: Iporps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tr key={index + 'module data'}>
        <td style={{ minWidth: '200px' }}>
          {autoCompleteMachineValue[index]._id && (
            <Operator
              field="operator"
              machineId={autoCompleteMachineValue[index]?._id}
              {...{ isView, process }}
            />
          )}
        </td>
        <td style={{ minWidth: '80px' }}>
          {isView ? (
            process.shift
          ) : (
            <CustomSelect
              name="shift"
              value={process.shift || ''}
              onChange={(e) => {
                handleChangeModuleData(e, process);
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
            </CustomSelect>
          )}
        </td>
        <td style={{ minWidth: '200px' }}>
          {isView
            ? process?.mc_no?.machine_no
            : process.part_id && (
                <AutocompleteMuiCustom
                  id="Machine"
                  label=""
                  option_name="machine_no"
                  arrayofObj={machineData}
                  value={process?.mc_no || ''}
                  onChange={(e, value) => {
                    if (value === null) value = null;
                    process.mc_no = value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
        </td>
        {/* {process.partNumber} */}
        <td style={{ width: '250px', minWidth: '250px' }}>
          {isView ? (
            process?.part_id && process?.part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.6rem',
                  }}
                >
                  {typeof process?.part_id === 'object' &&
                  process?.part_id !== null
                    ? process?.part_id?.part_name
                    : process?.partName || ''}
                  {!process?.part_id && 'Pick Part Name'}
                </p>
              </Grid>

              {process.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      process.part_id = null;
                      process.partNumber = null;
                      process.partName = null;
                      setRender((prev) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                      setPartIdx(index);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </td>

        <td style={{ minWidth: '200px' }}>
          <OperationSelect
            {...{ process, isView, setRender }}
            partId={
              typeof process.part_id === 'string'
                ? process?.part_id
                : process?.part_id?._id
            }
          />
        </td>

        <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            name="weld_obs_parameter"
            type="text"
            value={process.weld_obs_parameter}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '110px' }}>
          {isView ? (
            convToTime(process.time)
          ) : (
            <CustomMobileTimePickerMui
              value={process.time}
              onChange={(date: any) => {
                process.time = date;
                setRender((prev) => !prev);
              }}
              ampm={true}
              openTo="hours"
              views={['hours', 'minutes']}
            />
          )}
        </td>
        <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            name="nugget_test_obs"
            type="text"
            value={process.nugget_test_obs}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            name="gauge_test_obs_visual"
            type="text"
            value={process.gauge_test_obs_visual}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px' }}>
          <TableInput
            isView={isView}
            name="qty"
            type="number"
            value={process.qty}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            name="ndt_test"
            type="text"
            value={process.ndt_test}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px' }}>
          <TableInput
            isView={isView}
            name="rej_qty"
            type="number"
            value={process.rej_qty}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '80px' }}>
          <TableInput
            isView={isView}
            name="sign"
            type="text"
            value={process.sign}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            name="fiver_pin_change_record"
            type="text"
            value={process.fiver_pin_change_record}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px' }} colSpan={isView ? 2 : 2}>
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={process.remarks}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
