import { LuLayoutTemplate } from 'react-icons/lu';
import { MdEngineering } from 'react-icons/md';
import { MdCompress } from 'react-icons/md';
import { GrUserWorker } from 'react-icons/gr';
import { MdFactory } from 'react-icons/md';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';

const tiles = [
  {
    sNo: 1,
    link: '/common/BopTableList',
    isLink: true,
    icon: <MdEngineering size="5rem" color="#343a40" />,
    p: 'BOP',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/migList',
    isLink: true,
    icon: <MdFactory size="5rem" color="#1864ab" />,
    p: 'Mig Welding',
    span: 'Record',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/weldShop/PirList',
    isLink: true,
    icon: <GrUserWorker size="5rem" color="#e03131" />,
    p: 'WELD',
    span: 'SHOP',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/platingAndPowderCoating/PirList',
    isLink: true,
    icon: <LuLayoutTemplate size="5rem" color="#e03131" />,
    p: 'Plating &',
    span: 'Powder Coating',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/pressShop/PirList',
    isLink: true,
    icon: <MdCompress size="5rem" color="#343a40" />,
    p: 'Press',
    span: 'Shop',
    isIcon: true,
  },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text="Process Inspection Reports" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
