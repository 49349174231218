import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface ifeildType {
  id: string;
  option_name: string;
}

const AutocompleteMuiCustom = ({
  id,
  option_name,
  onChange,
  value,
  arrayofObj,
  label,
  sx,
  ...rest
}: {
  id: string;
  value: string | {};
  option_name: string;
  arrayofObj: [] | any;
  label?: string;
  variant?: 'standard';
  sx?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void;
}) => {
  return (
    <>
      <Autocomplete
        id={id}
        options={arrayofObj}
        getOptionLabel={(option) => {
          const properties = option_name.split('.');
          return (
            properties.reduce(
              (obj: any, property) => obj?.[property],
              option
            ) || ''
          );
        }}
        isOptionEqualToValue={(option, value) => {
          const properties = option_name.split('.');
          const optionValue = properties.reduce(
            (obj: any, property) => obj?.[property],
            option
          );
          const selectedValue = properties.reduce(
            (obj: any, property) => obj?.[property],
            value
          );
          return optionValue === selectedValue || true;
        }}
        value={value}
        onChange={(e: any, value) => onChange(e, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            label={label}
            {...rest}
          />
        )}
        sx={{ ...sx }}
      />
    </>
  );
};

export default AutocompleteMuiCustom;
