import { HiDocumentReport } from 'react-icons/hi';
import { CgCalendarToday } from 'react-icons/cg';
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { GiExitDoor } from 'react-icons/gi';
import { RenderTiles } from '../../components/common';
import { GrUser } from 'react-icons/gr';
import { FaHandsHelping } from 'react-icons/fa';
import { MdOutlineLiveHelp } from 'react-icons/md';

const adminTilesObj = [
  // {
  //   sNo: 1,
  //   link: '/admin/clause1',
  //   isLink: true,
  //   clauseNo: 1,
  //   p: 'PRODUCTION PREPARATION /',
  //   span: 'NEW PRODUCT DEVELOPEMENT',
  //   isIcon: false,
  // },
  // {
  //   sNo: 3,
  //   link: '/admin/clause3',
  //   isLink: true,
  //   clauseNo: 3,
  //   p: 'CHANGING',
  //   span: 'MANAGEMENT',
  //   isIcon: false,
  // },
  // {
  //   sNo: 4,
  //   link: '/admin/clause4',
  //   isLink: true,
  //   clauseNo: 4,
  //   p: 'STANDARDS',
  //   span: 'MANAGEMENT',
  //   isIcon: false,
  // },
  // {
  //   sNo: 5,
  //   link: '/admin/clause5',
  //   isLink: true,
  //   clauseNo: 5,
  //   p: 'EDUCATION AND',
  //   span: 'TRAINING',
  //   isIcon: false,
  // },
  // {
  //   sNo: 7,
  //   link: '/admin/clause7',
  //   isLink: true,
  //   clauseNo: 7,
  //   p: 'Supplier',
  //   span: 'Control',
  //   isIcon: false,
  // },
  // {
  //   sNo: 8,
  //   link: '/admin/clause8',
  //   isLink: true,
  //   clauseNo: 8,
  //   p: 'HANDLING ABNORMILITY',
  //   span: 'IN QUALITY',
  //   isIcon: false,
  // },
  // {
  //   sNo: 10,
  //   link: '/admin/clause10',
  //   isLink: true,
  //   clauseNo: 10,
  //   p: 'EQUIPMENT / INSPECTION',
  //   span: "EQUIPMENT'S MANAGEMENT",
  //   isIcon: false,
  // },
  // {
  //   sNo: 15,
  //   link: '/common/clause15',
  //   isLink: true,
  //   clauseNo: 15,
  //   p: 'ADEQUATE TESTING',
  //   span: 'FACILITY',
  //   isIcon: false,
  // },
  // {
  //   sNo: 18,
  //   link: '/admin/clause18',
  //   isLink: true,
  //   clauseNo: 18,
  //   p: 'SAFETY',
  //   span: '',
  //   isIcon: false,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/production',
  //   isLink: true,
  //   icon: <CgCalendarToday size="5rem" color="#212529" />,
  //   p: 'PRODUCTION',
  //   span: 'MANAGEMENT',
  //   isIcon: true,
  // },
  //
  // {
  //   sNo: 2,
  //   link: '/common/scrapNoteList',
  //   isLink: true,
  //   icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
  //   p: 'SCRAP NOTE',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/riDepartment',
  //   isLink: true,
  //   icon: <GiExitDoor size="5rem" color="#a12222" />,
  //   p: 'RI',
  //   span: 'DEPARTMENT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/processRejection',
  //   isLink: true,
  //   icon: <CgCalendarToday size="5rem" color="#212529" />,
  //   p: 'PROCESS REJECTION',
  //   span: 'SHEET',
  //   isIcon: true,
  // },
  {
    sNo: 9,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdOutlineLiveHelp size="5rem" color="#003566" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
];

const AdminHome = () => {
  return (
    <RenderTiles
      tilesObj={adminTilesObj}
      justify="center"
      // height="100vh"
      // columns="0fr"
    />
  );
};

export default AdminHome;
