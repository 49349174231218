import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  autoCompleteMachineValue: any;
  // handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  autoCompleteMachineValue,
  // handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <TBodyRow
              index={index}
              autoCompleteMachineValue={autoCompleteMachineValue}
              isView={isView}
              process={process}
              setIsOpenPopup={setIsOpenPopup}
              setPartIdx={setPartIdx}
              machineData={machineData}
            />
          ))}
      </tbody>
    </>
  );
};

export default TBody;
