import { AiOutlineCloudUpload } from 'react-icons/ai';
const tiles = [
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=masterProcedureClause1&heading=MASTER PROCEDURE',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'MASTER',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/clauseUploadList?documentType=listOfProblemClause1&heading=LIST OF PROBLEM',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'LIST OF',
    span: 'PROBLEM ',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/clauseUploadList?documentType=fmeaReviewPlanClause1&heading=FMEA REVIEW PLAN',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'FMEA REVIEW',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/clauseUploadList?documentType=evidenceOfImplementationClause1&heading=EVIDENCE OF IMPLEMENTATION',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'EVIDENCE OF',
    span: 'IMPLEMENTATION',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/clauseUploadList?documentType=summaryInPdcaClause1&heading=SUMMARY IN PDCA',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'SUMMARY IN',
    span: 'PDCA',
    isIcon: true,
  },
];

export default tiles;
