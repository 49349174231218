import { HiDocumentReport } from 'react-icons/hi';
import { MdOutlineCompress } from 'react-icons/md';
import { FaCompressAlt, FaCompressArrowsAlt } from 'react-icons/fa';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';

const tiles = [
  {
    sNo: 1,
    link: '/common/processRejection/dailyProductionList', // ?documentType=pressShop1and2&heading=Press Shop 1 and 2
    isLink: true,
    icon: <MdOutlineCompress size="5rem" color="#1864ab" />,
    p: 'PROCESS',
    span: 'REJECTION',
    isIcon: true,
  },
  // {
  //   sNo: 2,
  //   link: '/common/processRejection/dailyProductionList', //?documentType=pressShop3&heading=Press Shop 3
  //   isLink: true,
  //   icon: <FaCompressAlt size="5rem" color="#0b7285" />,
  //   p: 'PRESS SHOP',
  //   span: '3',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/processRejection/dailyProductionList', // ?documentType=weldShop&heading=Weld Shop
  //   isLink: true,
  //   icon: <FaCompressArrowsAlt size="4rem" color="#343a40" />,
  //   p: 'WELD ',
  //   span: 'SHOP',
  //   isIcon: true,
  // },
];

const InhouseTiles = () => {
  return (
    <>
      <ChildHeader text="IN-HOUSE REJECTION" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default InhouseTiles;
