import moment from 'moment';
import { useState } from 'react';
// import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={14 + 1 + 1}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff.Dated']}
        values={['FRM-QAS-C-01', '00', '01-04-2023']}
        heading="In-Process Inspection Report (Press Shop)"
      />
      <tr>
        <th colSpan={14 + 1 + 1} align="left">
          Date:{' '}
          {isView ? (
            formatDate(moduleData.moduleData_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_date)}
              onChange={(date) => {
                moduleData.moduleData_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={14 + 1 + 1} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        {/* <th colSpan={4} align="left">
          Part No.:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th> */}
      </tr>
      <tr>
        {[
          'M/c No.',
          'Process',
          'Time',
          'Parameters',
          'Std. Spec.',
          'Method',
          'Setup Approval(5 Pcs) Min./Max. Range',
          'Mid.',
          'End',
          'Inspector Signature',
          'Supervisor Signature',
          'Remarks',
        ].map((head) => (
          <th
            rowSpan={head === 'Mid.' || head === 'End' ? 1 : 2}
            colSpan={
              head === 'Setup Approval(5 Pcs) Min./Max. Range' ||
              head === 'Mid.' ||
              head === 'End' ||
              head === 'Remarks'
                ? 2
                : 1
            }
            style={{ textTransform: 'uppercase' }}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {Array.from({ length: 2 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        {Array.from({ length: 2 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 3}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
