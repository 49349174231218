import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { mergerRowsControlPlan } from './helpers/merger';
import { Honda, Mahale, Maruti } from './components/view';
import { useQuery } from '../../hooks/UseQuery';

interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  error?: string | null;
}
interface Iprops {
  hideHeader?: boolean;
}
const View = ({ hideHeader }: Iprops) => {
  const { partId, controlPlanId } = useParams();
  const [moduleData, setModuleData] = useState({ revisionData: [] });
  const customer_name = useQuery('customer_name');

  const getControlPlanAsPerCustomer = () => {
    if (/Maruti/i.test(customer_name || '')) {
      return <Maruti {...{ moduleData }} />;
    } else if (/Aston Martin/i.test(customer_name || '')) {
      // return <AustinControlPlan {...{ moduleData }} />;
    } else if (/Honda/i.test(customer_name || '')) {
      return <Honda {...{ moduleData }} />;
    } else {
      return <Mahale {...{ moduleData }} />;
    }
  };

  useEffect(() => {
    axios
      .get(`/api/controlPlan/${partId}/${controlPlanId}`)
      .then((res) => {
        const data: any = mergerRowsControlPlan(res.data);
        setModuleData(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      {hideHeader !== true && (
        <Box>
          <ChildHeader text="CONTROL PLAN"></ChildHeader>
        </Box>
      )}
      {getControlPlanAsPerCustomer()}
    </Box>
  );
};

export default View;
