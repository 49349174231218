/** ----- object type should be passed!!! ---------------  
 we have an array of array object. Multidimentional
 First layer for the rows  [[] []] its has 2 TableRow
 Inside each array we have ojects [{},{},{}] for the TableCell
 * */

type TableHeaderObj = {
  text: string;
  rowspan: number;
  colspan: number;
  style?: {};
};

const TableHeaderHtmlCustom = ({
  tableHeaderObj,
}: {
  tableHeaderObj: TableHeaderObj[][];
}) => {
  return (
    <>
      <>
        {tableHeaderObj.map((row, index) => (
          <tr
            style={{
              padding: '10px',
            }}
            key={`${row}cols`}
          >
            {row.map((col, colIdx) => (
              <th
                key={`${colIdx}cols`}
                align="center"
                rowSpan={col?.rowspan || 1}
                colSpan={col?.colspan || 1}
              >
                {col.text}
              </th>
            ))}
          </tr>
        ))}
      </>
    </>
  );
};

export default TableHeaderHtmlCustom;
