// import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
// import DatePickerMui from '../../../../components/common/DatePickerMui';
// import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
// import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  // machineData: any;
  // customerData: any;
  calculateCumulative: any;
  setModuleData: any;
  isView: boolean;
}

const THead = ({
  moduleData,
  isView,
  // machineData,
  // customerData,
  calculateCumulative,
  setModuleData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Doc No.', 'Rev No.', 'Rev Date']}
        values={['FM/MAINT/11', '1', '10.02.16']}
        heading="Tool History Card"
      />
      <tr>
        <th colSpan={8}></th>
        <th colSpan={2} align="left">
          Month:
          <TableInput
            name="month"
            type="text"
            value={moduleData.month}
            onChange={(e) => {
              moduleData.month = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name:- {moduleData?.part_name}
        </th>
        <th colSpan={5} align="left">
          Standard Tool Life :-
          <TableInput
            name="std_tool_life"
            type="text"
            value={moduleData.std_tool_life}
            onChange={(e) => {
              moduleData.std_tool_life = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Operation/Tool Name :-{' '}
          <TableInput
            name="op_tool_name"
            type="text"
            value={moduleData.op_tool_name}
            onChange={(e) => {
              moduleData.op_tool_name = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={5} align="left">
          Tool Re-sharping/ Re-griending Freq. :-
          <TableInput
            name="changed_frequncy"
            type="number"
            value={moduleData.changed_frequncy}
            onChange={(e) => {
              moduleData.changed_frequncy = e.target.value;
              calculateCumulative();
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'Tool Loading Date',
          'Tool Un-Loading Date',
          'M/c No.',
          'Prod. Qty',
          'Cumulative. Qty.',
          'Last Part/ Tool Condition',
          'Reason (If not ok)',
          'Action Taken',
          'Updated By,',
          'Remarks',
        ].map((head) => (
          <th
            key={head}
            style={{
              textTransform: 'uppercase',
              minWidth: `${head === 'Reason (If not ok)' && '300px'}`,
            }}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
