import { AiOutlineCloudUpload } from 'react-icons/ai';
import { ImUserTie } from 'react-icons/im';

const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause5&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 5,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/admin/employee_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
];

export default tiles;
