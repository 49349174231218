import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    operator: '',
    shift: '',
    mc_no: null,
    part_id: null,
    operation: '', // will come from cp
    weld_obs_parameter: '',
    time: '',
    nugget_test_obs: '',
    gauge_test_obs_visual: '',
    qty: null,
    ndt_test: '',
    rej_qty: null,
    sign: '',
    fiver_pin_change_record: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  pirWeldShop_date: moment(),
  processes: Array.from({ length: 25 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
