import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import ObservationInput from './ObservationInput';
// import CustomSelect from '../../../../../components/common/SelectField';
// import { IconButton, MenuItem, Tooltip } from '@mui/material';
// import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
// import DeleteIcon from '@mui/icons-material/Delete';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { convToTime } from '../../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Checkbox } from '@mui/material';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData?: any;
}
const TBody = ({ moduleData, tabValue, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.pirs[tabValue] &&
        moduleData.pirs[tabValue].pir_list &&
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) => (
          <tr key={index + tabValue + 11.333 + 'specs'}>
            {index === 0 && (
              <th
                colSpan={1}
                align="left"
                rowSpan={moduleData?.pirs[tabValue]?.pir_list?.length}
                style={{
                  minWidth: '200px',
                }}
              >
                {isView ? (
                  spec?.machine_id?.machine_no
                ) : (
                  <AutocompleteMuiCustom
                    id="Machine"
                    label="Machine"
                    option_name="machine_no"
                    key={tabValue + 'machine'}
                    arrayofObj={machineData}
                    value={
                      spec?.machine_id_autocomplete || spec?.machine_id || ''
                    }
                    onChange={(e, value) => {
                      spec.machine_id = value?._id;
                      spec.machine_id_autocomplete = value;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
            )}

            {index === 0 && (
              <td
                rowSpan={moduleData?.pirs[tabValue]?.pir_list?.length}
                style={{ minWidth: '200px' }}
              >
                {moduleData?.pirs[tabValue]?.process_name}
              </td>
            )}

            {index === 0 && (
              <td
                style={{ minWidth: '150px' }}
                rowSpan={moduleData?.pirs[tabValue]?.pir_list?.length}
              >
                {isView ? (
                  convToTime(spec?.time)
                ) : (
                  <TimePicker
                    value={moment(spec?.time)}
                    onChange={(date) => {
                      spec.time = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
            )}

            <td style={{ minWidth: '200px' }}>{spec.parameter}</td>
            <td style={{ minWidth: '200px' }}>{spec.specification}</td>
            <td style={{ minWidth: '200px' }}>{spec.methods}</td>
            <td style={{ minWidth: '150px' }}>
              <TableInput
                name="setup_min_range"
                type="text"
                value={spec.setup_min_range}
                onChange={(e) => {
                  spec.setup_min_range = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td style={{ minWidth: '150px' }}>
              <TableInput
                name="setup_max_range"
                type="text"
                value={spec.setup_max_range}
                onChange={(e) => {
                  spec.setup_max_range = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            {Array.from({ length: 4 }, (_, obsIndex) => (
              <td
                key={obsIndex + 'obs'}
                style={{ textTransform: 'uppercase', minWidth: '120px' }}
              >
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              </td>
            ))}
            <td style={{ minWidth: '150px' }}>
              <TableInput
                name="inspector_sign"
                type="text"
                value={spec.inspector_sign}
                onChange={(e) => {
                  spec.inspector_sign = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td style={{ minWidth: '150px' }}>
              <TableInput
                name="supervisor_sign"
                type="text"
                value={spec.supervisor_sign}
                onChange={(e) => {
                  spec.supervisor_sign = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td colSpan={isView ? 2 : 1} style={{ minWidth: '150px' }}>
              <TableInput
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            {!isView && (
              <td style={{ minWidth: '150px' }}>
                <Checkbox
                  name="changeCheckbox"
                  checked={spec.appearance === 'check3'}
                  onChange={() => {
                    if (spec.prev_appearance) {
                      spec.appearance = spec.prev_appearance;
                      spec.prev_appearance = null;
                    } else {
                      spec.prev_appearance = spec.appearance;
                      spec.appearance = 'check3';
                    }
                    setRender((prev) => !prev);
                  }}
                  style={{ color: '#064a29' }}
                />
              </td>
            )}
            {/* <td style={{ textTransform: 'uppercase' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="ng">NG</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td> */}
            {/* <th style={{ textTransform: 'uppercase', minWidth: '13%' }}>
              {isView &&
                (spec.remarks === 'other' ? spec.remarks_other : spec.remarks)}
              {!isView &&
                (spec.remarks === 'other' ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <TableInput
                        isView={isView}
                        name="cycle_time"
                        type="text"
                        defaultValue={spec.remarks_other}
                        onChange={(e) => {
                          spec.remarks_other = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => {
                            spec.remarks_other = '';
                            spec.remarks = '';
                            setRender((prev) => !prev);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.remarks || ''}
                    onChange={(e) => {
                      spec.remarks = e.target.value;
                      handleChangeColorOnRemarks(spec, spec.remarks);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="under deviation">UNDER DEVIATION</MenuItem>
                    <MenuItem value="tbc">TBC</MenuItem>
                    <MenuItem value="operation not done">
                      OPERATION NOT DONE
                    </MenuItem>
                    <MenuItem value="other">OTHER</MenuItem>
                  </CustomSelect>
                ))}
            </th> */}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
