import { Checkbox, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import { convToTime } from '../../../../../utils/helpers';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  isView: boolean;
  operation: string[];
  getProcess: any;
}
const tableHead: any = {
  setup: ['FRM-CMT-04', '00', '01-04-2023'],
  retro: ['', '00', '01-04-2023'],
  suspected: ['', '00', '01-04-2023'],
};

const heading: any = {
  setup: 'SETTING APPROVAL RECORDS',
  retro: 'RETROACTIVE CHECK INSPECTION RECORDS',
  suspected: 'SUSPECTED CHECK RECORDS',
};
const THead = ({ getProcess, moduleData, isView, operation }: IProps) => {
  const [render, setRender] = useState(false);
  const documentType = useQuery('documentType') || '';
  const machineName = useQuery('machine_name') || '';
  const operation_name = useQuery('operation_name') || '';
  return (
    <thead>
      <TableCompHead
        colSpan={17}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Eff. Date']}
        values={tableHead[documentType]}
        heading={heading[documentType]}
      />
      <tr>
        <th align="left" colSpan={4}>
          Customer Name:
          {moduleData?.customer_name ||
            moduleData.part_id?.customer_id?.customer_name}
        </th>
        <th align="left" colSpan={7}>
          Machine Name:{machineName}
        </th>
        <th colSpan={6} align="left">
          <Stack style={{ gap: '5px' }}>
            Date:-
            {isView ? (
              formatDate(moduleData.date)
            ) : (
              <DatePickerMui
                value={moment(moduleData.date || null)}
                onChange={(date) => {
                  moduleData.date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </Stack>
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Operation Name & No. :
          {isView ? (
            moduleData.operation_name
          ) : (
            <CustomSelect
              name="operation_name"
              value={moduleData.operation_name || operation_name || ''}
              onChange={(e) => {
                getProcess(e.target.value);
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">None</MenuItem>
              {operation.map((name) => (
                <MenuItem value={name}>{name}</MenuItem>
              ))}
            </CustomSelect>
          )}
        </th>
        <th colSpan={7}></th>
        <th colSpan={6} align="left">
          Sample Size :
          <TableInput
            isView={isView}
            name="sample_size"
            type="text"
            value={moduleData?.sample_size}
            onChange={(e) => {
              moduleData.sample_size = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          PART NAME: {moduleData.part_name || moduleData.part_id?.part_name}
        </th>
        <th colSpan={7} align="left">
          PART NO: {moduleData?.part_number || moduleData.part_id?.part_number}
        </th>

        <th colSpan={6} align="left"></th>
      </tr>
      <tr>
        <th rowSpan={2}>S.NO.</th>
        <th colSpan={2}>DESCRIPTION</th>
        <th rowSpan={2}>METHOD</th>
        <th colSpan={10}>OBSERVATIONS</th>
        <th rowSpan={2}>STATUS</th>
        <th rowSpan={2}>APPROVAL BY</th>
        <th rowSpan={2}>REMARKS</th>
      </tr>
      <tr>
        {['PARAMETER', 'SPECIFICATION', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
          (head: any, index) => (
            <th
              key={head + index + 'heading'}
              style={{ textTransform: 'uppercase' }}
            >
              {head}
            </th>
          )
        )}
      </tr>
    </thead>
  );
};

export default THead;
