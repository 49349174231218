import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { Box, Checkbox, Divider, Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  toolsData: any;
  departmentData: any;
}

const theadData = [
  'Breakdown Details',
  'Action Taken',
  'Spare Parts Used',
  'Remarks',
];

const THead = ({
  moduleData,
  isView,
  machineData,
  toolsData,
  departmentData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['FRM-MNT-0-004', '00', '01-04-2023']}
        heading="Break Down Slip"
      />
      <tr>
        <th colSpan={5} align="left">
          Type of Breakdown
        </th>
      </tr>

      <tr>
        {['Mechanical', 'Electrical', 'Electronic', 'Fixture'].map(
          (option, index) => (
            <th key={option + index} colSpan={index === 3 ? 2 : 1} align="left">
              {option}
              {/* {isView && (moduleData.type_of_breakdown === option ? '⬤' : '◯')} */}

              <Checkbox
                checked={moduleData.type_of_breakdown === option}
                onChange={(e) => {
                  moduleData.type_of_breakdown = option;

                  setRender((prev) => !prev);
                }}
                disabled={isView}
              />
            </th>
          )
        )}
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine/Tool Name:
          {moduleData?.machine_id?.machine_name ||
            moduleData?.machine_id_autocomplete?.machine_name}
          {moduleData?.machine_id && moduleData?.tool_id && ' / '}
          {moduleData?.tool_id?.tool_no ||
            moduleData?.tool_id_autocomplete?.tool_no}
        </th>
        <th align="left">Reported By: {moduleData?.report_prepared_by}</th>
        <th align="left" colSpan={2}>
          Completed By:{moduleData?.report_approved_by}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine/Tool No:
          {isView &&
            (moduleData?.machine_id?.machine_no ||
              moduleData?.machine_id_autocomplete?.machine_no)}
          {isView && '/'}
          {isView &&
            (moduleData?.tool_id?.tool_no ||
              moduleData?.tool_id_autocomplete?.tool_no)}
          {!isView && (
            <Stack direction="row" spacing={2}>
              <AutocompleteMuiCustom
                id="Machine No."
                label="Machine"
                option_name="machine_no"
                key={'machine'}
                arrayofObj={machineData}
                value={
                  moduleData?.machine_id_autocomplete ||
                  moduleData?.machine_id ||
                  ''
                }
                onChange={(e, value) => {
                  moduleData.machine_id = value?._id;
                  moduleData.machine_id_autocomplete = value;
                  setRender((prev) => !prev);
                }}
                sx={{ width: '350px' }}
              />
              <AutocompleteMuiCustom
                id="Tools"
                label="Tool No."
                option_name="tool_no"
                key={'tools'}
                arrayofObj={toolsData}
                value={
                  moduleData?.tool_id_autocomplete || moduleData?.tool_id || ''
                }
                onChange={(e, value) => {
                  moduleData.tool_id = value?._id;
                  moduleData.tool_id_autocomplete = value;
                  setRender((prev) => !prev);
                }}
                sx={{ width: '350px' }}
              />
            </Stack>
          )}
        </th>
        <th colSpan={3} align="left">
          Date :
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Department:
          {isView ? (
            moduleData?.department_id?.department_name
          ) : (
            <AutocompleteMuiCustom
              id="Department"
              label=""
              option_name="department_name"
              key={'tools'}
              arrayofObj={departmentData}
              value={
                moduleData?.department_id_autocomplete ||
                moduleData?.department_id ||
                ''
              }
              onChange={(e, value) => {
                moduleData.department_id = value?._id;
                moduleData.department_id_autocomplete = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Time:
          {isView ? (
            formatDate(moduleData.time)
          ) : (
            <TimePicker
              value={moment(moduleData.time)}
              onChange={(date) => {
                moduleData.time = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'Remarks' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
