import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Operator Name',
  'Shift',
  'M/C No',
  'Part Name',
  'Operation',
  'Weld Observation Parameter',
  'Inspection',
  'Qty.',
  'Ndt Test',
  'Rejection',
  'Sign',
  'Fiver Pin Change record',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={16}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Dated']}
        values={[' FRM-QAS-C-02', '00', '01-04-2023']}
        heading="Process Inspection Report ( Weld Shop )"
      />
      <tr>
        <th colSpan={2}> Date : </th>
        <th colSpan={14} align="left">
          {isView ? (
            formatDate(moduleData.pirWeldShop_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pirWeldShop_date)}
              onChange={(date) => {
                moduleData.pirWeldShop_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={heading === 'Inspection' ? 1 : 2}
            colSpan={heading === 'Inspection' ? 3 : 1}
          >
            {heading}
          </th>
        ))}
        <th colSpan={2} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        <th>Time</th>
        <th>Nugget Test Observation</th>
        <th>Gauge Test Observation Visual</th>
      </tr>
    </thead>
  );
};

export default THead;
