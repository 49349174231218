import { MdOutlineCalendarMonth } from 'react-icons/md';
import { SiInstructure } from 'react-icons/si';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { TbRulerMeasure } from 'react-icons/tb';

const tiles = [
  {
    sNo: 1,
    link: '/common/tilesCallibrationplan',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'CALLIBRATION',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/tilesMsaplan',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'MSA',
    span: 'PLAN',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/instrumentMasterlist',
    isLink: true,
    icon: <SiInstructure size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Instrument',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/MSAList',
    isLink: true,
    icon: <TbRulerMeasure size="5rem" color="#343a40" />,
    p: 'MSA',
    span: '',
    isIcon: true,
  },
];

const Clause = () => {
  return (
    <>
      <ChildHeader text="ADEQUATE TESTING FACILITY" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Clause;
