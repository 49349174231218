import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box, SelectChangeEvent } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import ParetoChart from './ParetoChart';
import GridInputs from '../../../../../../components/common/inputs/GridInputs';
//import DefectWise from '../../../../../clause8Page/customerConcernDashboards/components/dashboard/defectwise/DefectWise';
interface IProps {
  apiData: any;
}
const PartWiseQty = ({ apiData /*year, month, data*/ }: IProps) => {
  return (
    <div>
      <>
        <TController apiData={apiData} />
        <Box style={{ paddingBottom: '3rem', width: '50vw' }}>
          <ParetoChart apiData={apiData} />
        </Box>
      </>
    </div>
  );
};

export default PartWiseQty;
