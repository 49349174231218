interface IDocument {
  [type: string]: any;
}
interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  state: any;
  statusObj: {
    pending: number;
    accepted: number;
    rejected: number;
    [type: string]: any;
  };
  document: IDocument;
  documentIndex: number;
}
const ApprovalSelect = ({
  handleChangeStatus,
  state,
  statusObj,
  document,
  documentIndex,
}: IProps) => {
  console.log();
  return (
    <>
      {state?.user?.userType === 2 ? (
        <select
          style={{
            fontSize: '1.2rem',
            outline: 'none',
            border: 'none',
            padding: '5px',
            marginLeft: '0.5rem',
            width: '150px',
            textAlign: 'center',
            textTransform: 'capitalize',
            ...(document.is_admin_request === 'pending' && {
              backgroundColor: '#FDD835',
            }),
            ...(document.is_admin_request === 'accepted' && {
              backgroundColor: '#2b8a3e',
              color: '#fff',
            }),
            ...(document.is_admin_request === 'rejected' && {
              backgroundColor: '#f03e3e',
              color: '#fff',
            }),
          }}
          name="is_admin_request"
          value={
            statusObj[document?.is_admin_request] ||
            document?.is_admin_request_no
          }
          onChange={(e) => handleChangeStatus(e, documentIndex)}
        >
          <option value="1">PENDING</option>
          <option value="2">APPROVE</option>
          <option value="3">SEND BACK</option>
        </select>
      ) : document.isSubmitted ? (
        <div
          style={{
            fontSize: '1.4rem',
            outline: 'none',
            padding: '5px',
            textAlign: 'center',
            width: '200px',
            margin: 'auto',
            textTransform: 'uppercase',
            ...(document.is_admin_request === 'pending' && {
              backgroundColor: '#FDD835',
            }),
            ...(document.is_admin_request === 'accepted' && {
              backgroundColor: '#2b8a3e',
              color: '#fff',
            }),
            ...(document.is_admin_request === 'rejected' && {
              backgroundColor: '#f03e3e',
              color: '#fff',
            }),
            ...(document.is_admin_request === 'reject' && {
              backgroundColor: '#f03e3e',
              color: '#fff',
            }),
          }}
        >
          {document.is_admin_request === 'pending' && 'pending for approval'}
          {document.is_admin_request === 'accepted' && 'accepted'}
          {document.is_admin_request === 'rejected' && 'Sent back'}
          {document.is_admin_request === 'reject' && 'rejected'}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: '#FDD835',
            fontSize: '1.4rem',
            outline: 'none',
            padding: '5px',
            textAlign: 'center',
            width: '200px',
            margin: 'auto',
          }}
        >
          {document.isSubmitted ? 'SUBMITTED' : 'DRAFT'}
        </div>
      )}
    </>
  );
};

export default ApprovalSelect;
