import React, { useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import { RenderTable } from '../../../../../components/ui';
import { Table } from '../../../../../components/ui/renderTable/styled';

const RejectionTable = (props: any) => {
  const { process, isView } = props;
  const [render, setRender] = useState(false);

  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reasons_for_rej?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {[
                    'Blank  U/S',
                    'Blank O/S',
                    'Punching U/S',
                    'Punching O/S',
                    'Bending U/S',
                    'Bending O/S',
                    'Dim O/S',
                    'Dim U/S',
                    'Height O/S',
                    'Height U/S',
                    'Welding Nugget',
                    'Welding Stangth',
                    'Welding Flash',
                    'Welding Spatter',
                    'Burr',
                    'Dent',
                    'Creck',
                    'Scrateches',
                    'Rust',
                    'damage',
                    'Dia O/S',
                    'Dia U/S',
                    'CD Over Size',
                    'CD Under Size',
                    'Radius O/S',
                    'Radius U/S',
                    'Profile O/S',
                    'Profile U/S',
                    'Angle Over Size',
                    'Angle Under Size',
                    'Dimple Dia O/S',
                    'Dimple Dia U/S',
                    'Shearing Length  O/S',
                    'Raw Material Defects',
                  ].map((reason: string) => (
                    <MenuItem value={reason}>{reason}</MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejection</th>
          <th>{process.prod_qty - process.ok_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
