import { MdEmojiTransportation } from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';
import { MdOutlinePersonPin } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/admin/supplier_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'SUPPLIER',
    isIcon: true,
    icon: <MdEmojiTransportation size="5rem" color="#135089" />,
  },
  // {
  //   sNo: 3,
  //   link: '/common/masterListOfVendor',
  //   isLink: true,
  //   icon: <MdOutlinePersonPin size="5rem" color="#064a29" />,
  //   p: 'MASTER LIST OF',
  //   span: 'VENDOR',
  //   isIcon: true,
  // },
];

export default tiles;
