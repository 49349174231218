import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
// import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
interface IProps {
  moduleData: IData;
  machineData: any;
  toolsData: any;
  departmentData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  machineData,
  toolsData,
  departmentData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData,
            toolsData,
            departmentData,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
          }}
        />
        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
