import { Production } from '../pages/ProductionPage';
import {
  InhouseTiles,
  ProcessRejectionTiles,
} from '../pages/processRejectionSheet';
import {
  DailyProduction,
  CreateDailyProduction,
} from '../pages/ProductionPage/dailyProductionRotor';
import {
  ProcessRejectionList,
  ProcessRejectionCreate,
} from '../pages/processRejectionSheet/processRejection';
import {
  DailyProductionStrokes,
  CreateDailyProductionStrokes,
} from '../pages/ProductionPage/dailyProductionStrokes';
import {
  DailyProductionRedBin,
  CreateDailyProductionRedBin,
} from '../pages/ProductionPage/dailyProductionRedBin';
import {
  HourlyProductionReportList,
  CreateHourlyProductionReport,
} from '../pages/ProductionPage/HourlyProductionReport';

import {
  DailyRejectionRotorList,
  CreateDailyRejectionRotor,
} from '../pages/ProductionPage/dailyProductionRotorDailyRejection';
import PDCSection from '../pages/ProductionPage/PDCSection';
import PressShop from '../pages/ProductionPage/PressShop';
import RotorSection from '../pages/ProductionPage/RotorSection';
import { Profile } from '../pages/profile';
import CNCSection from '../pages/ProductionPage/CNCSection';
import {
  CNCRedBinList,
  CreateViewCNCRedBin,
} from '../pages/ProductionPage/redBinCNC';

import {
  PressShopRedBinList,
  CreateViewPressShopRedBin,
} from '../pages/ProductionPage/redBinPressShop';
import {
  RotorSectionRedBinList,
  CreateViewRotorSectionRedBin,
} from '../pages/ProductionPage/redBinRotorSection';
import {
  DailyHourlyCNC,
  CreateDailyHourlyCNC,
} from '../pages/ProductionPage/dailyProdCNCMachining';
import UnderConstructionPage from '../underconstruction-page';
import { ClauseUploadList } from '../pages/clauseUpload';
import { PdcHome, PressShopHome, RotorHome } from '../pages/clause3';
// clause 8 modules
import {
  AbnormalityLogBook,
  CreateAbnormalityLogBook,
} from '../pages/abnormalityLogBook';
import {
  BopTableList,
  BopCreate,
  PirTiles,
  MigList,
  MigCreate,
  CreateWeldShopPir,
  WeldShopPirList,
  PressShopList,
  PressShopCreate,
  PlatingPowderCoatingList,
  PlatingPowderCoatingCreate,
} from '../pages/pir';
import { VendirList } from '../pages/MasterListOfVendor';
import {
  ProcessControlStandardCreate,
  ProcessControlStandardList,
} from '../pages/processControlStandard';
import {
  CreateForMSetup,
  CreateForMSummary,
  SummaryTableList,
  CreateHoldCard,
  TrainingRecord,
} from '../pages/4m';
import {
  SpcPlanTiles,
  SpcPlanPlanList,
  SpcPlanPlanCreate,
  SpcPlanChecklistList,
  MasterListofSpcPlan,
} from '../pages/spcPlan';
import { CreateEditMsa, MSAList } from '../pages/MSA';
import { ScrapNoteList, CreateScrapNote } from '../pages/scrapNote';
import {
  ChecklistList,
  Clause15,
  InstrumentMasterList,
  MsaChecklistList,
  MsaPlanCreate,
  MsaPlanList,
  PlanCreate,
  PlanList,
  TilesCallibration,
  TilesMsa,
} from '../pages/clause15';

import {
  CreateInpsectionStandard,
  InspectionStandardList,
  RIDeparmentHome,
  RIPart,
  SupplierChecklist,
  SupplierPlanCreate,
  SupplierPlanList,
  // CreateInpsectionStandard,
  // InspectionStandardList,
  // RiDocumentUpload,
  // RIPart,
  // SupplierDocument,
  // SupplierDocumentHome,
  // VendorList,
} from '../pages/RIDeparment';
import {
  InspectionReport,
  InspectionReportList,
} from '../pages/inspectionReportImperial';
import ProcessRejectionDashboardTabs from '../pages/processRejectionSheet/rejectionNoteDashboard/RejectionAnalysisDashboardTabs';
import ProcessRejectionDashBoardList from '../pages/processRejectionSheet/rejectionNoteDashboard/TableList';
import { SamplingStd } from '../pages/RIDeparment/samplingStd';
import {
  PrevantiveMaintenanceChecklistCreate,
  PrevantiveMaintenanceChecklistList,
  PrevantiveMaintenancePlanCreate,
  PrevantiveMaintenancePlanList,
  PrevantiveMaintenanceTiles,
} from '../pages/prevantiveMaintenance';
import { MushinHelpDesk } from '../pages/Mushin_Help_Desk';

import ToolsList from '../pages/masterlisofTools/List';
import { CriticalSparePartsList } from '../pages/masterListOfCriticalSpareParts';
import ToolsHistory from '../pages/masterlisofTools/toolsHistory/CreateToolHistory';
import { BreakDownSlipList, CreateBreakDownSlip } from '../pages/breakDownSlip';

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

// scrap Note

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'pdc',
    element: <PDCSection />,
  },
  {
    path: 'rotorSection',
    element: <RotorSection />,
  },
  {
    path: 'pressShop',
    element: <PressShop />,
  },
  {
    path: 'cnc',
    element: <CNCSection />,
  },
];

export const processRejection = [
  {
    path: 'processRejection',
    element: <ProcessRejectionTiles />,
  },
  {
    path: 'inhouserejection/dashboardList',
    element: <InhouseTiles />,
  },
  {
    path: 'processRejection/dailyProductionList',
    element: <ProcessRejectionList />,
  },
  {
    path: 'createEditProcessRejection/:id?',
    element: <ProcessRejectionCreate />,
  },
  {
    path: 'processRejection/dashboard',
    element: <ProcessRejectionDashboardTabs />,
  },
  {
    path: 'processRejection/dashboardList',
    element: <ProcessRejectionDashBoardList />,
  },
];
export const productions = [
  {
    path: 'production',
    element: <Production />,
  },
  // {
  //   path: 'dailyProductionList',
  //   element: <DailyProduction />,
  // },

  // --------------------------- rotor section -------------------------------------------------------------------------
  // daily prod rotor report
  {
    path: 'rotorSection/dailyProductionList',
    element: <DailyProduction />,
  },
  {
    path: 'createEditDailyProduction/:id?',
    element: <CreateDailyProduction />,
  },

  // daily rotor rejection report
  {
    path: 'rotorSection/dailyProductionRotorDailyRejectionList',
    element: <DailyRejectionRotorList />,
  },
  {
    path: 'createEditDailyProductionRotorDailyRejection/:id?',
    element: <CreateDailyRejectionRotor />,
  },
  // daily production redbin list (doubt it is to be deleted or not)
  // {
  //   path: 'rotorSection/dailyProductionRedBinList',
  //   element: <DailyRejectionRotorList />,
  // },
  // {
  //   path: 'createEditDailyProductionRotorDailyRejection/:id?',
  //   element: <CreateDailyRejectionRotor />,
  // },
  // redbin prashant
  {
    path: 'rotorSection/redbinRotorSection',
    element: <RotorSectionRedBinList />,
  },
  {
    path: 'rotorSection/createEditRedbinRotorSection/:id?',
    element: <CreateViewRotorSectionRedBin />,
  },

  // ----------------------------------end of rotor section -----------------------------------------------------------------------

  // -------------------------------------------pdc section -------------------------------------------------------------------------
  // hourly report
  {
    path: 'pdc/rotorHourlyProductionList',
    element: <HourlyProductionReportList />,
  },
  {
    path: 'createHourlyProductionReports/:id?',
    element: <CreateHourlyProductionReport />,
  },

  // prodRed bin pdc section
  {
    path: 'pdc/dailyProductionRedBinList',
    element: <DailyProductionRedBin />,
  },
  {
    path: 'createEditDailyProductionRedBin/:id?',
    element: <CreateDailyProductionRedBin />,
  },

  // ---------------------------------------------- press shop section ----------------------------------------------
  // dailyprod strokes
  {
    path: 'pressShop/dailyProductionStrokesList',
    element: <DailyProductionStrokes />,
  },
  {
    path: 'createEditDailyProductionStrokes/:id?',
    element: <CreateDailyProductionStrokes />,
  },

  {
    path: 'pressShop/redbinPressShop',
    element: <PressShopRedBinList />,
  },
  {
    path: 'pressShop/createEditRedbinPressShop/:id?',
    element: <CreateViewPressShopRedBin />,
  },

  // redbin

  // ---------------------------------------------- end of press shop section ----------------------------------------------

  // ------------------------------------------------- cnc secction --------------------------------------------------------

  // hourly prod cnc
  {
    path: 'cnc/dailyHourlyProdCNCList',
    element: <DailyHourlyCNC />,
  },
  {
    path: 'cnc/createEditDailyHourlyProdCNC/:id?',
    element: <CreateDailyHourlyCNC />,
  },
  // redbin cnc
  {
    path: 'cnc/redbinCNC',
    element: <CNCRedBinList />,
  },
  {
    path: 'cnc/createEditRedbinCNC/:id?',
    element: <CreateViewCNCRedBin />,
  },
];

const clause3ForM = [
  {
    path: '4mPdc',
    element: <PdcHome />,
  },
  {
    path: '4mPressShop',
    element: <PressShopHome />,
  },
  {
    path: '4mRotor',
    element: <RotorHome />,
  },
];

const clause8Modules = [
  // abnormality Log book
  {
    path: 'abnormalityLogBookList',
    element: <AbnormalityLogBook />,
  },
  {
    path: 'createAbnormalityLogBook/:id?',
    element: <CreateAbnormalityLogBook />,
  },
];

const pir = [
  {
    path: 'pirtiles',
    element: <PirTiles />,
  },
  {
    path: 'bopcreate/:partId/:pirId?',
    element: <BopCreate />,
  },
  {
    path: 'BopTableList',
    element: <BopTableList />,
  },
  {
    path: 'migList',
    element: <MigList />,
  },
  {
    path: 'migCreate/:partId/:pirId?',
    element: <MigCreate />,
  },
  // weld shop

  {
    path: 'weldShop/PirList',
    element: <WeldShopPirList />,
  },
  {
    path: 'weldShop/createPir/:id?',
    element: <CreateWeldShopPir />,
  },
  // press shop
  {
    path: 'pressShop/PirList',
    element: <PressShopList />,
  },
  {
    path: 'pressShop/createPir/:partId/:pirId?',
    element: <PressShopCreate />,
  },
  // plating and powdder coating
  {
    path: 'platingAndPowderCoating/PirList',
    element: <PlatingPowderCoatingList />,
  },
  {
    path: 'platingAndPowderCoating/createPir/:partId/:pirId?',
    element: <PlatingPowderCoatingCreate />,
  },
  {
    path: 'platinglist',
    element: <UnderConstructionPage />,
  },
];
const forMSummary = [
  {
    path: 'create4mSummary',
    element: <CreateForMSummary />,
  },
  {
    path: '4mSummaryList',
    element: <SummaryTableList />,
  },
  {
    path: 'create4mSetup/:partId/:rowId/:setupId?',
    element: <CreateForMSetup />,
  },
  {
    path: 'createHoldCard/:id?',
    element: <CreateHoldCard />,
  },
];

const SpcPlan = [
  {
    path: 'spcPlanTiles',
    element: <SpcPlanTiles />,
  },
  {
    path: 'spcPlanPlanList',
    element: <SpcPlanPlanList />,
  },
  {
    path: 'spcPlanPlanCreate/:id',
    element: <SpcPlanPlanCreate />,
  },
  {
    path: 'spcPlanChecklistList',
    element: <SpcPlanChecklistList />,
  },
  {
    path: 'MasterListofspcPlan',
    element: <MasterListofSpcPlan />,
  },
];
const MSA = [
  {
    path: 'MSAList',
    element: <MSAList />,
  },
  {
    path: 'CreateEditMsa/:partId/:id?',
    element: <CreateEditMsa />,
  },
];

// inspection qlty std ri dept
const inspectionStandard = [
  {
    path: 'inspectionStandardList',
    element: <InspectionStandardList />,
  },
  {
    path: 'inspectionStandard/:inspectionStandardId?',
    element: <CreateInpsectionStandard />,
  },
];

const instrumentClause15 = [
  {
    path: 'instrumentplanlist',
    element: <PlanList />,
  },
  {
    path: 'instrumentplancreate/:id',
    element: <PlanCreate />,
  },
  {
    path: 'msaplanlist',
    element: <MsaPlanList />,
  },
  {
    path: 'msaplancreate/:id',
    element: <MsaPlanCreate />,
  },
  {
    path: 'msachecklistList',
    element: <MsaChecklistList />,
  },
  {
    path: 'instrumentMasterlist',
    element: <InstrumentMasterList />,
  },
  {
    path: 'checklistList',
    element: <ChecklistList />,
  },
];
const inspectionReportRoute = [
  {
    path: 'inspectionReportList',
    element: <InspectionReportList />,
  },
  {
    path: 'inspectionReport/:partId/:inspectionReportId?',
    element: <InspectionReport />,
  },
];

const prevantiveMaintenance = [
  {
    path: 'prevantiveMaintenanceTiles',
    element: <PrevantiveMaintenanceTiles />,
  },
  {
    path: 'prevantiveMaintenancePlanList',
    element: <PrevantiveMaintenancePlanList />,
  },
  {
    path: 'prevantiveMaintenacePlanCreate/:id',
    element: <PrevantiveMaintenancePlanCreate />,
  },
  {
    path: 'prevantiveMaintenanceChecklistList',
    element: <PrevantiveMaintenanceChecklistList />,
  },
  {
    path: 'prevantiveMaintenaceChecklistCreate/:moduleId?',
    element: <PrevantiveMaintenanceChecklistCreate />,
  },
];
export const IndexCommon: routes[] = [
  {
    path: 'riDepartment',
    element: <RIDeparmentHome />,
  },
  {
    path: 'riPart',
    element: <RIPart />,
  },
  {
    path: 'samplingStandard',
    element: <SamplingStd />,
  },
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'masterListOfVendor',
    element: <VendirList />,
  },
  // tools and critical spare parts
  // machine and tools
  {
    path: 'masterlistofTools',
    element: <ToolsList />,
  },
  {
    path: 'toolsHistory/:id',
    element: <ToolsHistory />,
  },
  {
    path: 'masterListOfCriticalSparePart',
    element: <CriticalSparePartsList />,
  },
  {
    path: 'processControlStandardList/:partId',
    element: <ProcessControlStandardList />,
  },
  {
    path: 'processControlStandardCreate/:partId/:id?',
    element: <ProcessControlStandardCreate />,
  },
  {
    path: 'supplierplanlist',
    element: <SupplierPlanList />,
  },
  {
    path: 'supplierplancreate/:id',
    element: <SupplierPlanCreate />,
  },
  {
    path: 'supplierchecklist',
    element: <SupplierChecklist />,
  },

  // scrap note
  {
    path: 'scrapNoteList',
    element: <ScrapNoteList />,
  },
  {
    path: 'createEditScrapNote/:id?',
    element: <CreateScrapNote />,
  },
  {
    path: 'clause15',
    element: <Clause15 />,
  },
  {
    path: 'tilesCallibrationplan',
    element: <TilesCallibration />,
  },
  {
    path: 'tilesMsaplan',
    element: <TilesMsa />,
  },

    //Mushin Help Desk
    {
      path: 'mushinHelpDesk',
      element: <MushinHelpDesk />,
    },


  // break down slip
  {
    path: 'breakDownSlipList',
    element: <BreakDownSlipList />,
  },
  {
    path: 'createEditBreakDownSlip/:id?',
    element: <CreateBreakDownSlip />,
  },
  {
    path: 'trainingRecord/:id?',
    element: <TrainingRecord />,
  },

  ...clause3ForM,
  ...productions,
  ...processRejection,
  ...clause8Modules,
  ...other,
  ...pir,
  ...forMSummary,
  ...SpcPlan,
  ...MSA,
  ...instrumentClause15,
  ...inspectionStandard,
  ...inspectionReportRoute,
  ...prevantiveMaintenance,
];
