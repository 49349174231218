import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { Table } from '../../../../components/ui/renderTable/styled';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  pictorial: any;
}

const PictorialTable = ({ moduleData, isView, pictorial }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table>
        <thead>
          <TableCompHead
            colSpan={32}
            rowSpan={3}
            labels={['Doc No', 'Rev', 'Eff. Date']}
            values={['FRM-CMT-02', '00', '01-04-2023']}
            heading="4M CHANGE  TRACKING   SHEET"
          />
          <tr>
            <th>Type of Change:</th>
            {pictorial.man.map((day: boolean, index: number) => (
              <th>{index + 1}</th>
            ))}
          </tr>
          <tr>
            <th>Man</th>
            {pictorial.man.map((day: boolean) => (
              <td>{day ? '⬤' : '◯'}</td>
            ))}
          </tr>
          <tr>
            <th>Machine</th>
            {pictorial.machine.map((day: boolean) => (
              <td>{day ? '⬤' : '◯'}</td>
            ))}
          </tr>
          <tr>
            <th>Method</th>
            {pictorial.method.map((day: boolean) => (
              <td>{day ? '⬤' : '◯'}</td>
            ))}
          </tr>
          <tr>
            <th>Material</th>
            {pictorial.material.map((day: boolean) => (
              <td>{day ? '⬤' : '◯'}</td>
            ))}
          </tr>
          <tr>
            <th style={{ minWidth: '120px' }}>Line Leader Name:</th>
            {moduleData.line_leader_name &&
              pictorial.man.map((day: boolean, index: number) => (
                <td>
                  <TableInput
                    isView={isView}
                    style={{ width: '120px' }}
                    name="line_leader_name"
                    type="text"
                    value={moduleData.line_leader_name[index]}
                    onChange={(e) => {
                      moduleData.line_leader_name[index] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ))}
          </tr>
        </thead>
      </Table>
    </div>
  );
};

export default PictorialTable;
